<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <div style="text-align: center;">
        <h1 class="mx-auto">登录后台管理</h1>
    </div>

    <v-form class="mx-auto application-form">
        <!-- 表单输入字段 -->
        <TitleModel>
            <h2>基本信息</h2>
        </TitleModel>
        <v-text-field label="学号" v-model="id" :rules="[required]"></v-text-field>
        <v-text-field label="密码" v-model="password" :rules="[required]"></v-text-field>



        <!-- 其他表单元素 -->
        <!-- ... -->
        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;" :loading="sendloading">登录</v-btn>
        </div>

    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel
    },
    data: () => ({
        name: '',
        phone: '',
        department: [],
        class_: '',

        // 编程部题目
        program_experience: '',
        direction: [],
        leader: [],

        // 运营部题目
        operate_experience: '',

        // 技术部题目
        technology_experience: '',

        // 双创部题目
        hlwplus_experience: '',
        office_experience: '',

        other_group: '',
        awards: '',
        intention: [],

        selfinfo: '',

        sendloading: false
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        send() {
            this.sendloading = true
            const dataToSend = {
                name: this.name,
                phone: this.phone,
                department: this.department,
                class_: this.class_,

                // 编程部题目
                program_experience: this.program_experience,
                direction: this.direction,
                leader: this.leader,

                // 运营部题目
                operate_experience: this.operate_experience,

                // 技术部题目
                technology_experience: this.technology_experience,

                // 双创部题目
                hlwplus_experience: this.hlwplus_experience,
                office_experience: this.office_experience,

                other_group: this.other_group,
                awards: this.awards,
                intention: this.intention,

                selfinfo: this.selfinfo
            };

            axios
                .post('http://114.132.50.110:3001/recruitment/sendApplication', dataToSend)
                .then(response => {
                    this.sendloading = false
                    this.$router.push('/recruitment/success');
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>