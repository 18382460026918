<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" temporary>
      <v-list density="compact" nav>
        <v-list-item @click="navigateToDestination('/')" prepend-icon="mdi-home" title="首页" value="home"></v-list-item>
        <v-list-item @click="navigateToDestination('/recruitment/application')" prepend-icon="mdi-text-box-outline"
          title="24届招新报名" value="application"></v-list-item>
        <v-list-item @click="navigateToDestination('/understand')" prepend-icon="mdi-text-box-outline" title="协会介绍"
          value="application"></v-list-item>
        <v-list-item @click="navigateToDestination('/repair')" prepend-icon="mdi-tools" title="报修申请"
          value="tools"></v-list-item>
        <v-list-item @click="navigateToDestination('/repairquery')" prepend-icon="mdi-clipboard-text-search"
          title="报修进度查询" value="repairquer"></v-list-item>
        <!-- <v-list-item @click="navigateToDestination('/recruitment/introduction')" prepend-icon="mdi-lightbulb-on"
          title="23届招新简章" value="recruitment"></v-list-item> -->
        <!-- <v-list-item @click="navigateToDestination('/assetslib')" prepend-icon="mdi-apps" title="下载中心" value="service"></v-list-item> -->
        <v-list-item @click="navigateToDestination('/about')" prepend-icon="mdi-information" title="关于我们"
          value="about"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-app-bar elevation="4">
        <!-- <template v-slot:image>
          <v-img src="./assets/logo.png"></v-img>
        </template> -->

        <template v-slot:prepend v-if="isMobile">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </template>

        <template v-slot:append>
          <div v-if="!isMobile">
            <v-btn @click="navigateToDestination('/')" style="font-weight: bold" prepend-icon="mdi-home">首页</v-btn>

            <v-btn @click="navigateToDestination('/recruitment/application')" style="font-weight: bold"
              prepend-icon="mdi-text-box-outline">24届招新报名</v-btn>
            <v-btn @click="navigateToDestination('/understand')" style="font-weight: bold"
              prepend-icon="mdi-account-group">协会介绍</v-btn>
            <v-btn @click="navigateToDestination('/repair')" style="font-weight: bold"
              prepend-icon="mdi-tools">报修申请</v-btn>
            <v-btn @click="navigateToDestination('/repairquery')" style="font-weight: bold"
              prepend-icon="mdi-clipboard-text-search">报修进度查询</v-btn>
            <!-- <v-btn @click="navigateToDestination('/recruitment/introduction')" style="font-weight: bold"
              prepend-icon="mdi-lightbulb-on">23届招新简章</v-btn> -->
            <!-- <v-btn @click="navigateToDestination('/assetslib')" style="font-weight: bold;" prepend-icon="mdi-apps">下载中心</v-btn> -->
            <v-btn @click="navigateToDestination('/about')" style="font-weight: bold"
              prepend-icon="mdi-information">关于我们</v-btn>
          </div>
        </template>
      </v-app-bar>

      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <v-spacer></v-spacer>
    <v-footer style="margin-top: 50px; background-color: rgba(40, 137, 217, 0.059)">
      <v-row justify="center" no-gutters>
        <v-col class="text-center" cols="12">
          <strong>互联网科技创新协会</strong>
        </v-col>

        <v-col class="text-center" cols="12">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="icp-link"
            style="color: black; text-decoration: none">粤ICP备2023043412号-1</a>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { ref, defineComponent } from "vue";

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

var isMobile = ref(false);
if (isMobileDevice()) {
  isMobile.value = true;
}

export default {
  name: "App",

  data: () => ({
    isMobile: isMobile,
    drawer: null,
  }),
  methods: {
    navigateToDestination(url) {
      this.$router.push(url); // 导航到目标页面
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fade-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

/* .fade-leave-active below version 2.1.8 */
.fade-leave-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
