<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <!-- <div style="text-align: center;">
        <h1 class="mx-auto">本届招新已经结束</h1>
        <h1 class="mx-auto">感谢你的支持，期待下次相遇</h1>
    </div> -->

    <v-form class="mx-auto application-form">

        <TitleModel>
            <h2>基本信息</h2>
        </TitleModel>
        <v-form>
            <v-text-field label="姓名" v-model="formData.name" :rules="[required]"></v-text-field>
            <v-text-field label="手机号" v-model="formData.phone" :rules="[required]"
                hint="将用于通知以及面试信息查询，请务必正确填写能联系到的手机号"></v-text-field>
            <v-select label="所在院系" v-model="formData.department"
                :items="['航空港管理学院', '民航电子信息工程学院', '民航经营管理学院', '飞机维修工程学院', '无人机学院', '空乘学院']" :rules="[required]"></v-select>
            <v-text-field label="所在班级" v-model="formData.class" :rules="[required]"></v-text-field>

            <TitleModel>
                <h2>纳新问卷</h2>
            </TitleModel>
            <v-select label="意向部门" v-model="formData.intention" :items="['编程部', '运营部', '技术部', '双创部', '电子部']"
                :rules="[required]" multiple chips hint="按照第一意向、第二意向等进行先后选择" persistent-hint></v-select>
            <a :href="www" style="color: black;">
                <v-btn @click="navigateToDestination('/about')" variant="text">
                    点此了解各部门
                </v-btn>
            </a>

            <div v-if="formData.intention.includes('编程部')">
                <TitleModel>
                    <h3>编程部问卷</h3>
                </TitleModel>

                <div>是否有经验和方向不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>

                <v-textarea v-model="formData.programmingExperience" style="margin-top: 20px;" label="是否有编程经验？"
                    placeholder="是否有编程经验？有的话是哪种编程语言？有没有作品或者成果？若没有填否即可。" auto-grow></v-textarea>
                <v-select label="你更倾向于哪个方向？" v-model="formData.programmingDirection"
                    :items="['Python 程序设计', 'Web 应用开发', '信息技术创新开发']"></v-select>
                <div>
                    <p><strong>Python 程序设计：</strong>主要方向是 Python 程序开发入门，并开展基于 Python Flask
                        框架的后端应用开发技术【对接方向：服务器应用开发、算法设计、后端开发】
                    </p>
                    <p><strong>Web 应用开发：</strong>主要方向是基于 Vue.js 框架的 Web 前端应用开发【对接方向：网站开发、小程序开发、跨平台应用软件开发】</p>
                    <p><strong>信息技术创新开发：</strong>主要方向是基于 ArkTS+Cangjie 的 OpenHarmony 及其衍生系统应用软件开发；后端服务开发；基于 ArkUI-X
                        的全平台应用开发【对接方向：信创应用开发、鸿蒙软件开发、服务器应用开发、跨平台应用软件开发】</p>
                </div>
            </div>

            <div v-if="formData.intention.includes('运营部')">
                <TitleModel>
                    <h3>运营部问卷</h3>
                </TitleModel>
                <div>是否有经验不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
                <v-textarea v-model="formData.mediaExperience" style="margin-top: 20px;" label="对新媒体运营感兴趣吗？了解多少？"
                    placeholder="是否有推文撰写、视频剪辑、自媒体（如抖音等）、账号运营等经验？有没有作品或者成果？若没有填否即可。" auto-grow></v-textarea>

                <v-textarea v-model="formData.editingSkills" style="margin-top: 20px;" label="是否有文案编辑、PS以及视频剪辑能力？"
                    placeholder="若没有填否即可。" auto-grow></v-textarea>
            </div>

            <div v-if="formData.intention.includes('技术部')">
                <TitleModel>
                    <h3>技术部问卷</h3>
                </TitleModel>
                <div>是否有经验不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
                <v-textarea v-model="formData.hardwareExperience" style="margin-top: 20px;" label="是否有处理/维修、电脑软硬件经验？"
                    placeholder="是否有维修电脑硬件、处理软件问题、组装电脑、手机等的经验？若没有填否即可。" auto-grow></v-textarea>
            </div>

            <div v-if="formData.intention.includes('双创部')">
                <TitleModel>
                    <h3>双创部问卷</h3>
                </TitleModel>
                <div>是否有经验不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
                <v-textarea v-model="formData.innovationExperience" style="margin-top: 20px;" label="是否对互联网+创新创业大赛有所了解或经验？"
                    placeholder="若没有填否即可" auto-grow></v-textarea>
                <v-textarea v-model="formData.officeSkills" label="是否熟练运用Office办公套件？"
                    placeholder="可以简单说一下熟悉PPT、Excel还是Word，有什么使用经验，若没有填否即可。" auto-grow></v-textarea>
            </div>

            <div v-if="formData.intention.includes('电子部')">
                <TitleModel>
                    <h3>电子部问卷</h3>
                </TitleModel>
                <div>是否有经验不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
                <v-textarea v-model="formData.circuitExperience" style="margin-top: 20px;" label="对电路、单片机的开发了解么？"
                    placeholder="若没有填否即可" auto-grow></v-textarea>
                <v-textarea v-model="formData.projectIdeas" style="margin-top: 20px;" label="关于项目制作有没有什么好的想法？"
                    placeholder="若没有填否即可" auto-grow></v-textarea>
            </div>

            <v-text-field style="margin-top: 20px;" label="是否有参加其他社团/组织？加入了什么？"
                v-model="formData.otherGroups"></v-text-field>
            <v-textarea v-model="formData.awards" label="是否有获得过什么奖项或荣誉？"
                placeholder="没有的话填无即可，可以是任何类型的奖项或荣誉，有没有不影响录取，只是为了了解你~" auto-grow></v-textarea>
            <v-textarea v-model="formData.selfIntroduction" label="简短的自我介绍" auto-grow></v-textarea>
        </v-form>

        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">提交</v-btn>
        </div>

    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel
    },
    data: () => ({

        sendloading: false,

        formData: {
            name: '',
            phone: '',
            department: null,
            class: '',
            intention: [],
            programmingExperience: '',
            programmingDirection: '',
            mediaExperience: '',
            editingSkills: '',
            hardwareExperience: '',
            innovationExperience: '',
            officeSkills: '',
            circuitExperience: '',
            projectIdeas: '',
            otherGroups: '',
            awards: '',
            selfIntroduction: ''
        },
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        send() {
            alert('请注意，报名成功后会出现一个微信群二维码，要求你加入已报名通知群，请务必扫码加入！我们将在这个群通知面试和录取信息！')

            this.sendloading = true

            axios
                .post('http://114.132.50.110:3001/recruitment/sendApplication', this.formData)
                .then(response => {
                    this.sendloading = false
                    this.$router.push('/recruitment/success');
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>