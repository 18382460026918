<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>录取结果查询</h1>
    </TitleModel>
    <v-form class="mx-auto application-form" v-if="name == ''">
        <!-- 表单输入字段 -->
        <v-text-field label="手机号" v-model="phone" :rules="[required]" placeholder="输入报名时填写的手机号以查询录取结果"></v-text-field>
        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">查询</v-btn>
        </div>



    </v-form>

    <v-card class="application-form mx-auto" style="margin-top: 25px;" v-if="showchenggong">

        <v-card-title style="text-align: center;">
            <v-icon icon="mdi-check-decagram" size="80" color="success"></v-icon>
            <h2 style="margin: 15px;">恭喜你已被录取</h2>
        </v-card-title>
        <v-card-subtitle style="text-align: center;">欢迎加入我们！接下来请仔细阅读下面的信息</v-card-subtitle>
        <v-card-text>
            <p style="font-size: 1.1rem;"><strong>姓名：</strong>{{ name }}</p>
            <p style="font-size: 1.1rem; margin-top: 5px;"><strong>录取部门：</strong>{{ bumen }}</p>
            <p style="font-size: 1.1rem; margin-top: 5px;" v-if="zuzhang != ''"><strong>录取方向：</strong>{{ admitted }}</p>
        </v-card-text>
        <v-card-text>
            <p>恭喜你通过了我们的面试，互联科创协会欢迎你的到来！接下来有几项事情需要你去做，请确保逐项完成。</p>

            

            <div v-if="zc">
                <p style=" margin-top: 20px; font-weight: bold; font-size: 1rem;">1. 完成互联科创账号注册</p>
                <v-text-field label="学号" style="width: 100%;" class="mx-auto" v-model="userid"
                    :rules="[required]"></v-text-field>
                <v-text-field label='真实姓名' style="width: 100%;" class="mx-auto" v-model="name" readonly
                    :rules="[required]"></v-text-field>

                <v-text-field label="手机号" style="width: 100%;" class="mx-auto" v-model="phone" readonly
                    :rules="[required]"></v-text-field>

                <v-select label="所属部门" style="width: 100%;" class="mx-auto" readonly
                    :items="['编程部', '运营部', '技术部', '双创部', '电子部', '竞赛与项目部', '数学协会', '数学协会-数学建模部', '数学协会-学术部', '数学协会-宣传部', '数学协会-秘书部']"
                    v-model="bumen"></v-select>

                <v-text-field label="密码" style="width: 100%;" class="mx-auto" v-model="password" :rules="[required]"
                    type="password"></v-text-field>
                <v-text-field label="确认密码" style="width: 100%;" class="mx-auto" v-model="repassword" :rules="[required]"
                    type="password"></v-text-field>

                <v-text-field label="互联科创成员注册码" style="width: 100%;" class="mx-auto" v-model="registercode" readonly
                    :rules="[required]"></v-text-field>
                
                    <p>由互联科创统一认证平台提供服务</p>

                <v-btn @click="register()" block style="width: 150px; margin-bottom: 50px;" size="large" elevation="0"
                    color="blue-darken-1">注册</v-btn>
                
            </div>
            <p style=" margin-top: 20px; font-weight: bold; font-size: 1rem;">2. 加入互联科创协会总群</p>
            <p style=" margin-top: 10px; font-weight: bold;">
                首先请扫描下面的二维码加入咱协会的总群，请注意，不要把这个二维码透露给别人(若二维码无法显示请刷新后再试或联系微信：leoleeym)！</p>
            <p style=" margin-top: 10px; font-weight: bold;">加入总群后，请修改你的群名片为“姓名 部门 班级”，例如“张三 编程部
                24计应1班”，在接下来的两天里。总群可能会有很多的通知，请务必关注不要错过哦！</p>
            <v-img style="margin-top: 10px" src="../../assets/总群二维码.jpg"></v-img>
            <v-divider style="margin: 5px;"></v-divider>
            <p style=" margin-top: 20px; font-weight: bold; font-size: 1rem;">亲爱的创新者，你好</p>
            <p style="margin-top: 10px;">欢迎加入互联网科技创新协会！</p>
            <p style="margin-top: 10px;">
                在这个充满无限可能的时代，创新已不再是遥不可及的梦想，而是触手可及的现实。我们鼓励你大胆尝试、勇于探索，因为每一个独特的想法都拥有改变世界的潜力。你已经踏上了一段激动人心的创新之旅，未来的无限可能正等待着你的探索与实现！
            </p>
            <p style="margin-top: 10px;">
                我们的协会是一个汇聚最有激情和创造力的年轻人才的平台。这里，你将与志同道合的伙伴携手共进，共同面对挑战，突破自我。我们提供丰富的项目机会，让你能够参与前沿技术的实践，学习最新的技术趋势，并将理论知识转化为实际能力，为你的职业发展奠定坚实的基础。
            </p>
            <p style="margin-top: 10px;">
                在这个充满活力的社群中，我们鼓励你发声，分享你的想法，激发灵感。每一次讨论、每一个合作，都是我们一起成长的契机。我们相信，只有通过团结与合作，才能在快速发展的科技浪潮中把握机遇、开创未来。</p>
            <p style="margin-top: 10px;">感谢你选择加入互联网科技创新协会！我们期待在这段激动人心的旅程中，与每一位成员共同探索未知，创造属于我们的美好未来！</p>
            <p style="margin-top: 20px; text-align: right;">互联网科技创新协会</p>
        </v-card-text>

    </v-card>

    <v-card class="application-form mx-auto" style="margin-top: 25px;" v-if="showshibai">

        <v-card-title style="text-align: center;">
            <v-icon icon="mdi-emoticon-sad" size="80" color="warning"></v-icon>
            <h2 style="margin: 15px;">很遗憾</h2>
        </v-card-title>
        <v-card-text>
            <p>首先，我代表互联科创协会衷心感谢你对我们社团浓厚的兴趣和积极的参与，不管结果如何，你都在我们的心中留下了深刻的印象。</p>
            <p style=" margin-top: 10px;">我们明白，面试并不总是决定性的，它不能完全反映你的全部潜力和价值。有时候，我们的决策可能受到各种因素的影响，这并不代表你不具备所需的能力或素质。</p>
            <p style=" margin-top: 10px;">
                尽管我们无法录取每一位同学，请不要因此而感到沮丧或失望。相反，我们鼓励你继续追求自己的兴趣和热情，探索其他机会，不断成长和进步。我们深信，每一位参与面试的同学都有着独特的闪光点，这些闪光点将继续闪耀在你们的未来道路上。
            </p>
            <p style=" margin-top: 10px;">最后，我们要感谢你参与互联科创的招新，继续努力，勇往直前，我们期待着与你在未来的某个时刻相遇，一同创造更多美好的回忆。</p>
            <p style=" margin-top: 20px; text-align: right;">互联网科技创新协会</p>
        </v-card-text>

    </v-card>

    <v-snackbar v-model="snackbar" :timeout="timeout" color="red">
        {{ text }}

        <template v-slot:actions>
            <v-btn variant="text" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        phone: '',
        sendloading: false,
        snackbar: false,
        text: 'My timeout is set to 2000.',

        name: '',
        bumen: '',
        admitted: '',
        registercode: '',
        password: '',
        repassword: '',
        userid: '',
        zc: true,

        data: null,

        showchenggong: false,
        showshibai: false,

        selfinfo: ''

    }),
    methods: {
        register() {
            if (
                this.userid == '' ||
                this.username == '' ||
                this.phone == '' ||
                this.password == '' ||
                this.repassword == '' ||
                this.department == [] ||
                this.registercode == ''
            ) {
                this.snackbar = true
                this.text = '请完整填写注册信息'
                return
            }

            if (this.password != this.repassword) {
                this.snackbar = true
                this.text = '两次密码输入不一致'
                return
            }

            if (this.password.length < 6) {
                this.snackbar = true
                this.text = '密码不应短于6位'
                return
            }

            if (this.phone.length != 11) {
                this.snackbar = true
                this.text = '手机号不合法'
                return
            }

            if (this.userid.length != 11) {
                this.snackbar = true
                this.text = '学号不合法'
                return
            }

            var requestData = {
                userid: this.userid,
                username: this.name,
                password: this.password,
                department: this.bumen,
                registercode: this.registercode,
                phone: this.phone
            }

            axios.post('https://authapi.gcacitia.com/auth/register-itia', requestData)
                .then(response => {
                    // 请求成功处理
                    alert('注册成功');
                    this.zc = false

                })
                .catch(error => {
                    alert(`注册失败，${error.response.data.error}`)
                });

        },
        send() {
            this.sendloading = true
            axios.post('https://api.gcacitia.com/home/recruitment/sendQuery', { phone: this.phone, lq: 1 })
                .then(response => {
                    this.sendloading = false
                    this.showbuchong = false
                    this.showinfo = false

                    if (response.data.admission != null) {
                        if (response.data.error == 'tuple index out of range') {
                            alert('查询失败，手机号不存在，若需要帮助请联系微信：leoleeym')
                            return
                        }

                        this.name = response.data.name
                        this.bumen = response.data.admission
                        this.registercode = response.data.registercode

                        if (response.data.admission == '编程部') {
                            this.admitted = response.data.admitted
                        }

                        this.showchenggong = true
                        this.showshibai = false
                    } else {
                        this.name = response.data.name
                        this.showshibai = true
                        this.showchenggong = false

                    }


                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        },

        sendupdate() {
            this.sendloading = true
            axios.post('http://114.132.50.110:3001/recruitment/sendUpdate', { id: this.id, selfinfo: this.selfinfo })
                .then(response => {
                    this.sendloading = false
                    this.showbuchong = false
                    this.showinfo = false
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        }

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>