<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>录取结果查询</h1>
    </TitleModel>
    <v-form class="mx-auto application-form" v-if="name == ''">
        <!-- 表单输入字段 -->
        <v-text-field label="手机号" v-model="phone" :rules="[required]" placeholder="输入报名时填写的手机号以查询录取结果"></v-text-field>
        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">查询</v-btn>
        </div>



    </v-form>

    <v-card class="application-form mx-auto" style="margin-top: 25px;" v-if="showchenggong">

        <v-card-title style="text-align: center;">
            <v-icon icon="mdi-check-decagram" size="80" color="success"></v-icon>
            <h2 style="margin: 15px;">恭喜你已被录取</h2>
        </v-card-title>
        <v-card-subtitle style="text-align: center;">欢迎加入我们！接下来请仔细阅读下面的信息</v-card-subtitle>
        <v-card-text>
            <p style="font-size: 1.1rem;"><strong>姓名：</strong>{{ name }}</p>
            <p style="font-size: 1.1rem; margin-top: 5px;"><strong>录取部门：</strong>{{ bumen }}</p>
            <p style="font-size: 1.1rem; margin-top: 5px;" v-if="zuzhang != ''"><strong>录取组长：</strong>{{ zuzhang }}</p>
        </v-card-text>
        <v-card-text>
            <p>恭喜你通过了我们的面试，互联科创协会欢迎你的到来！接下来有几项事情需要你完成。</p>
            <p style=" margin-top: 10px; font-weight: bold;">首先请扫描下面的二维码加入咱协会的总群，请注意，不要把这个二维码透露给别人(若二维码无法显示请刷新后再试或联系微信：leoleeym)！</p>
            <p style=" margin-top: 10px; font-weight: bold;">加入总群后，请修改你的群名片为“姓名 部门 班级”，例如“张三 编程部
                23计应3班”，在接下来的两天里。总群可能会有很多的通知，请务必关注不要错过哦！</p>
            <p style=" margin-top: 10px; font-weight: bold;">我们协会将在下周一(10.16 18:30)召开23届第一次全体大会，请务必注意到场哦！</p>
            <v-img style="margin-top: 10px" src="../../assets/总群二维码.jpg"></v-img>
            <v-divider style="margin: 5px;"></v-divider>
            <p style=" margin-top: 10px;">当互联网科技的无限可能性汇聚在一个地方，创新不再是梦想，而是现实。亲爱的协会新成员和未来的创新者，欢迎加入互联网科技创新协会！</p>
            <p style=" margin-top: 10px;">在这里，我们鼓励大胆尝试、不断探索，相信每一个独特的想法都有改变世界的潜力。你已经踏上了一段令人兴奋的创新之旅。现在，让我们一同探索未知、实现梦想。</p>
            <p style=" margin-top: 10px;">
                我们的社团旨在探索、创新和实践，是一个汇聚着最聪明、最有激情的年轻人的地方。在这里，你将找到志同道合的伙伴，一起探索未知领域，挑战自己的极限。你将有机会参与项目，学习最新的技术趋势，为你的职业道路奠定坚实的基础。
            </p>
            <p style=" margin-top: 10px;">感谢你的信任和支持，成为互联网科技创新协会的一员。我们期待着在这个激动人心的旅程中，一起创造属于我们的未来！</p>
            <p style=" margin-top: 20px; text-align: right;">互联网科技创新协会</p>
        </v-card-text>

    </v-card>

    <v-card class="application-form mx-auto" style="margin-top: 25px;" v-if="showshibai">

        <v-card-title style="text-align: center;">
            <v-icon icon="mdi-emoticon-sad" size="80" color="warning"></v-icon>
            <h2 style="margin: 15px;">很遗憾</h2>
        </v-card-title>
        <v-card-text>
            <p>首先，我代表互联科创协会衷心感谢你对我们社团浓厚的兴趣和积极的参与，不管结果如何，你都在我们的心中留下了深刻的印象。</p>
            <p style=" margin-top: 10px;">我们明白，面试并不总是决定性的，它不能完全反映你的全部潜力和价值。有时候，我们的决策可能受到各种因素的影响，这并不代表你不具备所需的能力或素质。</p>
            <p style=" margin-top: 10px;">
                尽管我们无法录取每一位同学，请不要因此而感到沮丧或失望。相反，我们鼓励你继续追求自己的兴趣和热情，探索其他机会，不断成长和进步。我们深信，每一位参与面试的同学都有着独特的闪光点，这些闪光点将继续闪耀在你们的未来道路上。
            </p>
            <p style=" margin-top: 10px;">最后，我们要感谢你参与互联科创的招新，继续努力，勇往直前，我们期待着与你在未来的某个时刻相遇，一同创造更多美好的回忆。</p>
            <p style=" margin-top: 20px; text-align: right;">互联网科技创新协会</p>
        </v-card-text>

    </v-card>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        phone: '',
        sendloading: false,

        name: '',
        bumen: '',
        zuzhang: '',

        showchenggong: false,
        showshibai: false,

        selfinfo: ''

    }),
    methods: {
        send() {
            this.sendloading = true
            axios.post('http://114.132.50.110:3001/recruitment/sendQuery', { phone: this.phone })
                .then(response => {
                    this.sendloading = false
                    this.showbuchong = false
                    this.showinfo = false

                    if (response.data.admission != null) {
                        if (response.data.error == 'tuple index out of range') {
                            alert('查询失败，手机号不存在，若需要帮助请联系微信：leoleeym')
                            return
                        }

                        this.name = response.data.name
                        this.bumen = response.data.admission

                        if (response.data.admission == '编程部') {
                            this.zuzhang = response.data.admitted
                        }

                        this.showchenggong = true
                        this.showshibai = false
                    } else {
                        this.name = response.data.name
                        this.showshibai = true
                        this.showchenggong = false
                    }


                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        },

        sendupdate() {
            this.sendloading = true
            axios.post('http://114.132.50.110:3001/recruitment/sendUpdate', { id: this.id, selfinfo: this.selfinfo })
                .then(response => {
                    this.sendloading = false
                    this.showbuchong = false
                    this.showinfo = false
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        }

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}</style>